import React, { useState, useEffect } from "react";
import { measures } from "config/measures";
import { lightTheme } from "config/colors";
import { Grid, Stack, Typography, CardContent, Box } from "@mui/material";
import SubmitButton from "components/common/buttons/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/store/apps/auth";
import { fetchData } from "redux/store/apps/user";
import { connect } from "react-redux";
import { ReactComponent as BeBetterLogo } from "assets/images/home/BeBetterLogo.svg";
import { ReactComponent as Trophy } from "assets/images/home/trophy.svg";
import { ReactComponent as MenuIcon } from "assets/images/home/menu.svg";
import { ReactComponent as Cross } from "assets/images/home/x.svg";
//import CogOutline from 'mdi-material-ui/CogOutline'
//import BellOutline from 'mdi-material-ui/BellOutline'
import ExitToApp from "mdi-material-ui/ExitToApp";
import { HeadQuestionOutline, FileDocumentOutline } from "mdi-material-ui";
import Avatar from "@mui/material/Avatar";
//import Badge from '@mui/material/Badge'
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "reactstrap";
import { ReactComponent as CalendarIcon } from "assets/images/home/calendar.svg";
import getTheme from "../../../components/common/theme";
import Lottie from 'react-lottie';
import * as Loading from 'components/common/spinner/loading';
import { t } from "i18next";
import { PiTreeStructure } from 'react-icons/pi';

const Topbar = ({ menuOpen, toggleMenu, logged, accessToken, theme }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.auth);
  const [order, setOrder] = useState("ASC");
  const location = useLocation();

  const handleLogout = () => {
    dispatch(logout()).then((window.location.href = "/login"));
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {
    if (Object.entries(logged).length === 0) {
      navigate("/login");
    }
  }, [logged]);

  useEffect(() => {
    if (logged.length !== 0) {
      dispatch(
        fetchData({
          accessToken: accessToken,
          max_results: 1000,
          order: order,
        })
      );
    }
  }, [dispatch, order]);

  useEffect(() => {
    const items = document.getElementById("menu-icon");
    if (items) {
      if (menuOpen) {
        items.classList.add("open");
      } else {
        items.classList.remove("open");
      }
    }
  }, [menuOpen]);

  return (
    <Grid container sx={{ height: "100%", p: 0, width: "100%" }}>
      <Card className="w-100 b-radius border-0 shadow">
        <CardContent sx={{ height: "100%", padding: "0 !important" }}>
          <Grid container sx={{ height: "100%", p: 0, width: "100%" }}>
            {windowWidth >= 992 ? (
              <>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    px: 3,
                  }}
                >
                  <Stack
                    spacing={0.5}
                    direction="row"
                    alignItems="center"
                    onClick={() => navigate("/home")}
                    sx={{
                      cursor: "pointer",
                      ":hover .home": {
                        color: lightTheme.be_better_orange,
                        transition: "0.2s",
                      },
                    }}
                  >
                    <Lottie options={defaultOptions} style={{ width: 20 }} />
                    <Typography
                      variant="body"
                      className="home"
                      sx={{
                        pt: 0.25,
                        fontWeight: "500",
                        fontSize: 18,
                        color: location?.pathname === "/home" ? lightTheme.be_better_orange : "",
                      }}
                    >
                      Início
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={0.5}
                    direction="row"
                    alignItems="center"
                    onClick={() => navigate("/events")}
                    sx={{
                      cursor: "pointer",
                      ml: 5,
                      ":hover .agenda": {
                        color: lightTheme.be_better_orange,
                        transition: "0.2s",
                      },
                      ":hover .agendaIcon": {
                        stroke: lightTheme.be_better_orange,
                        transition: "0.2s",
                      },
                    }}
                  >
                    <CalendarIcon
                      className="agendaIcon"
                      style={{
                        width: 22.5,
                        width: 22.5,
                        stroke:
                          location?.pathname === "/events" ? lightTheme.be_better_orange : "",
                      }}
                    />
                    <Typography
                      className="agenda"
                      variant="body"
                      sx={{
                        pt: 0.25,
                        fontWeight: "500",
                        fontSize: 18,
                        color:
                          location?.pathname === "/events" ? lightTheme.be_better_orange : "",
                      }}
                    >
                      Agenda
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={0.5}
                    direction="row"
                    alignItems="center"
                    onClick={() => navigate("/news")}
                    sx={{
                      cursor: "pointer",
                      ml: 5,
                      ":hover .noticia": {
                        color: lightTheme.be_better_orange,
                        transition: "0.2s",
                      },
                      ":hover .noticiaIcon": {
                        fill: lightTheme.be_better_orange,
                        transition: "0.2s",
                      },
                    }}
                  >
                    <FileDocumentOutline
                      className="noticiaIcon"
                      style={{
                        width: 22.5,
                        width: 22.5,
                        fill: location?.pathname === "/news" ? lightTheme.be_better_orange : "",
                      }}
                    />
                    <Typography
                      variant="body"
                      className="noticia"
                      sx={{
                        pt: 0.25,
                        fontWeight: "500",
                        fontSize: 18,
                        color: location?.pathname === "/news" ? lightTheme.be_better_orange : "",
                      }}
                    >
                      Notícias
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={0.5}
                    direction="row"
                    alignItems="center"
                    onClick={() => navigate("/quiz")}
                    sx={{
                      cursor: "pointer",
                      ml: 5,
                      ":hover .quiz": {
                        color: lightTheme.be_better_orange,
                        transition: "0.2s",
                      },
                      ":hover .quizIcon": {
                        fill: lightTheme.be_better_orange,
                        transition: "0.2s",
                      },
                    }}
                  >
                    <HeadQuestionOutline
                      className="quizIcon"
                      style={{
                        width: 22.5,
                        width: 22.5,
                        fill: location?.pathname === "/quiz" ? lightTheme.be_better_orange : "",
                      }}
                    />
                    <Typography
                      variant="body"
                      className="quiz"
                      sx={{
                        pt: 0.25,
                        fontWeight: "500",
                        fontSize: 18,
                        color: location?.pathname === "/quiz" ? lightTheme.be_better_orange : "",
                      }}
                    >
                      Quiz
                    </Typography>
                  </Stack>
                  {store.loggedUser?.chefe &&
                    <Stack
                      spacing={0.5}
                      direction="row"
                      alignItems="center"
                      onClick={() => navigate("/beSupport")}
                      sx={{
                        cursor: "pointer",
                        ml: 5,
                        ":hover .toolbox": {
                          color: lightTheme.be_better_orange,
                          transition: "0.2s",
                        },
                        ":hover .toolboxIcon": {
                          fill: lightTheme.be_better_orange,
                          transition: "0.2s",
                        },
                      }}
                    >
                      <PiTreeStructure className="toolboxIcon" size={22.5} style={{ fill: location?.pathname === "/toolbox" ? lightTheme.be_better_orange : "" }} />
                      <Typography
                        variant="body"
                        className="toolbox"
                        sx={{
                          pt: 0.25,
                          fontWeight: "500",
                          fontSize: 18,
                          color: location?.pathname === "/toolbox" ? lightTheme.be_better_orange : "",
                        }}
                      >
                        {t('toolbox.title')}
                      </Typography>
                    </Stack>
                  }
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    px: 3,
                    ml: "auto",
                  }}
                >
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{ display: "flex", alignItems: "center", mr: 5 }}
                  >
                    <Box
                      onClick={() =>
                        navigate(`/user/details/${store.loggedUser?.id}`, {
                          state: { value: "definitions" },
                        })
                      }
                      sx={
                        store.loggedUser?.tipo === "volunteer" ||
                          store.loggedUser?.tipo === "ambassador"
                          ? {
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            background:
                              "linear-gradient(to bottom right, #3492F4,#5672ED)",
                            borderRadius: "50%",
                            width: "2.8rem",
                            height: "2.8rem",
                            ":hover": {
                              transform: "scale(1.05)",
                              transition: "0.2s",
                            },
                          }
                          : {
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            background:
                              "linear-gradient(to bottom right, #3492F4,#5672ED)",
                            borderRadius: "50%",
                            width: "3rem",
                            height: "3rem",
                            border: "2px solid #F28915",
                            ":hover": {
                              transform: "scale(1.05)",
                              transition: "0.2s",
                            },
                          }
                      }
                    >
                      {store.loggedUser?.tipo === "ambassador" && (
                        <img
                          src="../../assets/images/topbar/BordaEmbaixador.png"
                          alt=""
                          style={{
                            position: "absolute",
                            width: "3rem",
                            height: "3.4rem",
                            bottom: -2,
                            zIndex: 1,
                          }}
                        />
                      )}
                      {store.loggedUser?.tipo === "volunteer" && (
                        <img
                          src="../../assets/images/topbar/BordaVoluntario.png"
                          alt=""
                          style={{
                            position: "absolute",
                            width: "3rem",
                            height: "3.4rem",
                            bottom: -2,
                            zIndex: 1,
                          }}
                        />
                      )}
                      <Avatar
                        alt={store.loggedUser?.avatar}
                        src={`/assets/images/avatars/avatar${store.loggedUser?.avatar}.png`}
                        sx={{
                          width: "2.5rem",
                          height: "2.5rem",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                    <Stack direction="column">
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "600",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {store.loggedUser?.userDetails?.nome}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "300",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {store.loggedUser?.nickname}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{ display: "flex", alignItems: "center", mr: 5 }}
                  >
                    <Box
                      sx={{
                        ":hover": {
                          transform: "scale(1.05)",
                          transition: "0.2s",
                        },
                      }}
                    >
                      <Trophy
                        onClick={() =>
                          navigate(`/user/details/${store.loggedUser?.id}`, {
                            state: { value: "rank" },
                          })
                        }
                        style={{ height: "3rem", cursor: "pointer" }}
                      />
                    </Box>
                    <Stack direction="column">
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "600",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 1,
                        }}
                      >
                        Rank #{store.loggedUser?.rank}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "300",
                          fontSize: "12px",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {store.loggedUser?.pontos
                          ? store.loggedUser?.pontos
                          : "0"}{" "}
                        pontos
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    onClick={() => handleLogout()}
                    sx={{
                      borderLeft: '1px solid gray',
                      paddingLeft: '20px',
                      cursor: "pointer",
                      ":hover .logout": {
                        color: lightTheme.be_better_orange,
                        transition: "0.2s",
                      },
                      ":hover .logoutIcon": {
                        fill: lightTheme.be_better_orange,
                        transition: "0.2s",
                      },
                    }}
                  >
                    <ExitToApp fontSize="medium" className="logoutIcon" />
                    <Typography
                      variant="body"
                      className="logout"
                      sx={{ fontWeight: "300", fontSize: 18 }}
                    >
                      Logout
                    </Typography>
                  </Stack>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    pl: 1,
                  }}
                >
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{ display: "flex", alignItems: "center", mr: 1 }}
                  >
                    <Box
                      onClick={() =>
                        navigate(`/user/details/${store.loggedUser?.id}`, {
                          state: { value: "definitions" },
                        })
                      }
                      sx={
                        store.loggedUser?.tipo === "volunteer" ||
                          store.loggedUser?.tipo === "ambassador"
                          ? {
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            background:
                              "linear-gradient(to bottom right, #3492F4,#5672ED)",
                            borderRadius: "50%",
                            width: "2.6rem",
                            height: "2.6rem",
                            ":hover": {
                              transform: "scale(1.05)",
                              transition: "0.2s",
                            },
                          }
                          : {
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            background:
                              "linear-gradient(to bottom right, #3492F4,#5672ED)",
                            borderRadius: "50%",
                            width: "2.5rem",
                            height: "2.5rem",
                            border: "2px solid #F28915",
                            ":hover": {
                              transform: "scale(1.05)",
                              transition: "0.2s",
                            },
                          }
                      }
                    >
                      {store.loggedUser?.tipo === "ambassador" && (
                        <img
                          src="../../assets/images/topbar/BordaEmbaixador.png"
                          alt=""
                          style={{
                            position: "absolute",
                            width: "2.8rem",
                            height: "3.2rem",
                            bottom: -3,
                            zIndex: 1,
                          }}
                        />
                      )}
                      {store.loggedUser?.tipo === "volunteer" && (
                        <img
                          src="../../assets/images/topbar/BordaVoluntario.png"
                          alt=""
                          style={{
                            position: "absolute",
                            width: "2.8rem",
                            height: "3.2rem",
                            bottom: -2,
                            zIndex: 1,
                          }}
                        />
                      )}
                      <Avatar
                        alt={store.loggedUser?.avatar}
                        src={`/assets/images/avatars/avatar${store.loggedUser?.avatar}.png`}
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                    <Stack direction="column">
                      <Typography variant="body2" sx={{ fontWeight: "600" }}>
                        {store.loggedUser?.userDetails?.nome}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "300" }}>
                        {store.loggedUser?.nickname}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={() =>
                      navigate(`/user/details/${store.loggedUser?.id}`, {
                        state: { value: "rank" },
                      })
                    }
                  >
                    <Trophy style={{ height: "2.7rem" }} />
                    <Stack direction="column">
                      <Typography variant="body2" sx={{ fontWeight: "600" }}>
                        Rank #{store.loggedUser?.rank}
                      </Typography>
                      <Typography sx={{ fontWeight: "300", fontSize: "12px" }}>
                        {store.loggedUser?.pontos
                          ? store.loggedUser?.pontos
                          : "0"}{" "}
                        pontos
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    ml: "auto",
                    pr: 1,
                    zIndex: 3,
                  }}
                >
                  {/* <SubmitButton className="menuButton" width="2.5rem" height="2.5rem" 
              text={menuOpen ? <Cross/> : <MenuIcon/>} request={toggleMenu} 
              /> */}
                  <Grid>
                    <a
                      className="menu-icon"
                      style={{
                        background: `${getTheme(theme)?.button_gradient1}`,
                      }}
                      id="menu-icon"
                      data-behavior="open-mobile-menu"
                      onClick={() => toggleMenu()}
                    >
                      <span className="lines"></span>
                    </a>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    logged: state?.auth?.loggedUser,
    accessToken: state.auth.credentials.access_token,
    theme: state?.Customizer?.theme,
  };
};

export default connect(mapStateToProps)(Topbar);
