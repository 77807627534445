import React from "react";
import moment from 'moment';
import { Card } from "reactstrap";
import { Icon } from "@iconify/react";

const Footer = () => {
  return (
    <Card className="h-full b-radius border-0 shadow">
      <div className="grid grid-cols-12 items-center px-5 w-full h-full">
        <div className="col-span-6 md:col-span-3 flex items-center">
          <img
            src="../../../assets/images/logos/AdNorte.jpg"
            alt="AdNorte"
            className="h-[2rem] w-auto"
          />
        </div>
        <div className="hidden md:flex col-span-6 justify-center items-center">
          © {moment(new Date()).format('yyyy')} Águas do Norte. Todos os direitos reservados
        </div>
        <div className="col-span-6 md:col-span-3 flex justify-end items-center gap-3">
          <a
            href="https://www.facebook.com/aguasdonorte"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon="mdi:facebook" width="25" className="text-slate-500 hover:text-[#F28915] cursor-pointer" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCYJmtSmv0jRTlelAzS2hSyQ"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon="mdi:youtube" width="25" className="text-slate-500 hover:text-[#F28915] cursor-pointer" />
          </a>
          <a
            href="https://www.linkedin.com/company/12634619"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon="mdi:linkedin" width="25" className="text-slate-500 hover:text-[#F28915] cursor-pointer" />
          </a>
          <span className="text-[#F28915] font-bold text-xl">
            v2.0
          </span>
        </div>
      </div>
    </Card>
  );
};

export default Footer;
