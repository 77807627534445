import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Topbar from "layout/components/topbar";
import Menu from "layout/components/menu";
import Footer from "layout/components/footer";
import { useLocation } from "react-router";
import { Row, Col } from "reactstrap";
import { motion } from "framer-motion";

const AppLayout = ({ children }) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const headerExceptions = [
    "login",
    "regist",
    "accountActivation",
    "recoverAccount",
    "404",
  ];
  const footerExceptions = [
    "login",
    "regist",
    "accountActivation",
    "recoverAccount",
    "404",
    "events",
    "faqs",
    "user",
    "news",
    "home",
    "notifications",
    "read-qr",
    "quiz",
    "check-in",
    "beSupport",
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleTopBarAndFooter = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].includes(location.pathname.split("/")[1])) {
        return false;
      }
    }
    return true;
  };

  const styles = {
    container: {
      backgroundImage: "url('../../assets/images/home/bg-home.png')",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      overflow: "auto",
    },
  };

  return (
      <div className="d-flex vh-100 w-100 noScroll" style={styles.container}>
       <main className={windowWidth >= 768 ? `w-100 h-100 ${toggleTopBarAndFooter(headerExceptions) && 'p-4'}` : `w-100 h-100 ${toggleTopBarAndFooter(headerExceptions) && 'p-3'}`}>
          <Col className="h-100 w-100">
            {toggleTopBarAndFooter(headerExceptions) && (
              <Row
                style={{
                  height: toggleTopBarAndFooter(headerExceptions)
                    ? "10%"
                    : "0%",
                }}
              >
                <Topbar toggleMenu={toggleMenu} menuOpen={menuOpen}/>
                <Menu
                  className="m-0 p-0"
                  menuOpen={menuOpen}
                  toggleMenu={toggleMenu}
                />
              </Row>
            )}

            <Row
              className={`d-flex justify-content-center align-items-center ${
                toggleTopBarAndFooter(headerExceptions) ? "py-3" : "py-0"
              }`}
              style={{
                height:
                  toggleTopBarAndFooter(footerExceptions) &&
                  toggleTopBarAndFooter(headerExceptions)
                    ? "80%"
                    : !toggleTopBarAndFooter(footerExceptions) &&
                      toggleTopBarAndFooter(headerExceptions)
                    ? "90%"
                    : "100%",
              }}
            >
              {children}
            </Row>

            {toggleTopBarAndFooter(footerExceptions) && (
              <Row
                style={{
                  height: toggleTopBarAndFooter(footerExceptions)
                    ? "10%"
                    : "0%",
                }}
              >
                <Footer />
              </Row>
            )}
          </Col>
        </main>
      </div>
  );
};

export default AppLayout;
